export default function (container, form) {
    const focusableElements =
      'a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    const firstFocusableElement = container.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = container.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    document.addEventListener("keydown", (e) => {
      let isTabPressed = e.key === "Tab" || e.keyCode === 9;
      if (e.key === "Escape") {
        form.show_modal = false;
      }
      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus(); // add focus for the first focusable element
          e.preventDefault();
        }
      }
    });

    firstFocusableElement.focus();
}