<template>
      <div class="modal__container" ref="modal">
        <header v-if="slot_props">
          <h3 class="modal__formheading flex justify-between gap-4 items-center bg-gray-100" >
            <span>{{ heading }}</span>
            <button class="modal__close" aria-label="close modal" @click.prevent="closeModal"><span>&times;</span></button>
          </h3>
        </header>
        <slot></slot>
      </div>
</template>

<script>
import tabTrapper from "../services/tabTrapper"

export default {
  props: [
    "slot_props",
    "heading"
  ],
  emits: ['close'],
  methods: {
    closeModal() {
      this.$emit('close')
      this.slot_props.state.show_modal = false
    }
  },
  mounted() {
    if(this.slot_props) {
      tabTrapper(this.$refs.modal, this.slot_props.state)
    }
  }
};
</script>